import { SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';
import ActionMenu from 'shared/ui/table/ActionMenu';
import ViewDeviceLogsMenuItem from 'app/features/billing/ui/components/DevicesAndBillingSummary/menus/ViewDeviceLogsMenuItem';

const DevicesAndBillingActionMenu = ({ vsgDuid, id }) => {
  return (
    <>
      {/* id for this menu need a way to be consumed by the menu */}
      <ActionMenu id={id}>
        <SimpleDropdownItem>
          <ViewDeviceLogsMenuItem vsgDuid={vsgDuid} id="viewDeviceLogsMenuItem" />
        </SimpleDropdownItem>
      </ActionMenu>
    </>
  );
};

export default DevicesAndBillingActionMenu;
