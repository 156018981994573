import { SubscriptionAlertIconWrapper } from 'shared/styles/components/DevicesAndBilling';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

export const getSubscriptionWarningIcon = (device) => {
  return device.decorated.isExpired ? (
    <SubscriptionAlertIconWrapper id="subscriptionWarningIcon">
      <IconSvgComponent
        svgFileName={'warning'}
        alt="The subscription for this device has expired"
        title="The subscription for this device has expired"
      />
    </SubscriptionAlertIconWrapper>
  ) : (
    <SubscriptionAlertIconWrapper id="subscriptionWarningIcon">
      <IconSvgComponent
        svgFileName={'warning-black'}
        alt="The subscription for this device will expire soon"
        title={`The subscription for this device will expire in ${
          device.decorated.monthsLeftInSubscription
        } month${device.decorated.monthsLeftInSubscription === 1 ? '' : 's'} or less`}
      />
    </SubscriptionAlertIconWrapper>
  );
};
