import {
  baseApiUrlDev,
  baseApiUrlProd,
  baseApiUrlStaging,
  baseApiUrlTest,
  baseApiUrlApp,
} from 'shared/constants/api';

export const getEnv = () => {
  const host = window && window.location.host;
  return host.split('.')[0];
};

const apiUrl = (() => {
  const host = window && window.location.host;

  //
  // This is NOT subbed out during the build process
  //
  return baseApiUrlDev;

})();
export default apiUrl;
