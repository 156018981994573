import useDevicesAndBillingSummaryProviderContext from 'app/features/billing/services/providers/hooks/useDevicesAndBillingSummaryProviderContext';
import SubscriptionSummaryHeader from 'app/features/billing/ui/components/DevicesAndBillingSummary/SubscriptionSummaryHeader';
import DevicesAndBillingExportButton from 'app/features/billing/ui/components/DevicesAndBillingSummary/buttons/DevicesAndBillingExportButton';
import DevicesSummaryFilterCheckbox from 'app/features/billing/ui/components/DevicesAndBillingSummary/checkboxes/DevicesSummaryFilterCheckbox';
import DevicesAndBillingSummaryCardContainer from 'app/features/billing/ui/components/DevicesAndBillingSummary/mobile/DevicesAndBillingSummaryCardContainer';
import SubscriptionAlertBox from 'app/features/billing/ui/components/DevicesAndBillingSummary/widgets/SubscriptionAlertBox';
import DevicesAndBillingSummaryTable from 'app/features/billing/ui/components/tables/DevicesAndBillingSummaryTable';
import { useState } from 'react';
import ListingCount from 'shared/components/ListingCount';
import MediaWrapper from 'shared/components/MediaWrapper';

import { ExportDiv, ExportWrapper } from 'shared/styles/components/PageList';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';

const DevicesAndBillingSummary = () => {
  const {
    actions: { toggleSubscriptionExpiredFilter, toggleUnallocatedDevicesFilter },
    state: {
      deviceSubscriptions,
      filteredDeviceSubscriptions,
      subscriptionExpirationSummary,
      organizationSubscriptionSummary,
      onlyShowUnallocatedDevicesFilter,
    },
  } = useDevicesAndBillingSummaryProviderContext();
  const [listingCount, setListingCount] = useState(0);

  const { needAttention } = subscriptionExpirationSummary;

  return deviceSubscriptions ? (
    <>
      <div>
        {needAttention.length > 0 && (
          <SubscriptionAlertBox toggleFilter={toggle => toggleSubscriptionExpiredFilter(toggle)} />
        )}
        <SubscriptionSummaryHeader
          organizationSubscriptionSummary={organizationSubscriptionSummary}
        ></SubscriptionSummaryHeader>
      </div>
      {deviceSubscriptions.length > 0 && (
        <ExportWrapper>
          <ExportDiv>
            <DevicesAndBillingExportButton />
          </ExportDiv>
        </ExportWrapper>
      )}

      <>
        <ListingCount
          listingCount={listingCount}
          totalCount={deviceSubscriptions.length}
          itemTypeName={'Device'}
        />
        <DevicesSummaryFilterCheckbox
          checked={onlyShowUnallocatedDevicesFilter}
          handleChecked={() => toggleUnallocatedDevicesFilter()}
        />

        <MediaWrapper
          mobileComponent={<DevicesAndBillingSummaryCardContainer />}
          nonMobileComponent={
            <DevicesAndBillingSummaryTable handleUpdateListingCount={setListingCount} />
          }
        />
      </>
    </>
  ) : (
    <LoadingOverlay />
  );
};

export default DevicesAndBillingSummary;
