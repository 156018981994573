import { useContext } from 'react';

import { DevicesAndBillingContext } from 'app/features/billing/services/providers/DevicesAndBillingSummaryProvider/DevicesAndBillingSummaryProvider';

const useDevicesAndBillingSummaryProviderContext = () => {
  //THIS WILL CHANGE, PLACEHOLDER FOR NOW
  const devicesAndBillingContext = useContext(DevicesAndBillingContext);

  if (!devicesAndBillingContext) {
    throw 'useDevicesAndBillingSummaryProviderContext must be used within a DevicesAndBillingSummaryProvider';
  }

  return devicesAndBillingContext;
};

export default useDevicesAndBillingSummaryProviderContext;
