import { useEffect, useState } from 'react';
import Media from 'react-media';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getGeofenceLogsData } from 'core/api/geofences';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import GeofenceLogCard from 'features/geofences/shared/mobile/GeofenceLogCard';
import DateRangeSubmitForm from 'shared/components/DateRangeSubmitForm';
import { SORT_ORDER } from 'shared/constants/table';
import { NoDataDiv } from 'shared/styles/components/Analytics';
import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import { Cards } from 'shared/styles/components/MobileCard';
import PageListWrapper, {
  DateWrapper,
  ExportDiv,
  ExportWrapper,
  PageListHead,
  PageListTitle,
  TableCheckboxFilterWrapper,
} from 'shared/styles/components/PageList';
import { TableFilterSpan } from 'shared/styles/components/Table';
import WcpTable from 'app/shared/components/controls/WcpTable/WcpTable';
import { sortDescendingAlpha } from 'shared/utilities/general';
import { sizes } from 'shared/utilities/media';
import {
  formatISOTimestampToLongDateWithTime,
  formatISOTimestampToShortDate,
  getAMonthAgo,
  getNow,
} from 'shared/utilities/time';
import { validateGeofenceLogsRange } from 'shared/utilities/validators';
import GeofenceActivityExportButton from 'features/geofences/buttons/GeofenceActivityExportButton';

const GeofenceLogsPage = () => {
  const { geofenceId } = useParams();

  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const [geofenceLogsData, setGeofenceLogsData] = useState([]);
  const [geofenceName, setGeofenceName] = useState(null);
  const [filterNotActiveCheckboxIsChecked, setFilterNotActiveCheckboxIsChecked] = useState(true);
  const [filteredLogsData, setFilteredLogsData] = useState(geofenceLogsData);
  const [listingCount, setListingCount] = useState(filteredLogsData?.length);
  const [serverError, setServerError] = useState(false);

  const defaultStartDate = formatISOTimestampToShortDate(getAMonthAgo());
  const defaultEndDate = formatISOTimestampToShortDate(getNow());

  const onSubmit = async ({ startDate, endDate }) => {
    try {
      let data = await getGeofenceLogsData({
        geofenceId: geofenceId,
        organizationId: filteredOrganizationId,
        startDate: startDate,
        endDate: endDate,
      });
      setGeofenceName(data.response.message.geofence_name);
      setGeofenceLogsData(data.response.message.logs);
      setServerError(false);
    } catch (err) {
      logWcpError(err);
      setServerError(true);
    }
  };

  useEffect(() => {
    if (filterNotActiveCheckboxIsChecked) {
      let filteredLogs = geofenceLogsData.filter(l => l.while_active !== false);
      setFilteredLogsData(
        filteredLogs?.sort((l1, l2) => sortDescendingAlpha(l1.timestamp, l2.timestamp)),
      );
    } else {
      setFilteredLogsData(
        geofenceLogsData?.sort((l1, l2) => sortDescendingAlpha(l1.timestamp, l2.timestamp)),
      );
    }
    geofenceLogsData?.forEach(log => {
      log.formattedTime = formatISOTimestampToLongDateWithTime(log.timestamp);
      log.activeDisplay = log.while_active ? 'Active' : 'Not Active';
    });
  }, [geofenceLogsData, filterNotActiveCheckboxIsChecked]);

  useEffect(() => {
    setListingCount(filteredLogsData?.length ? filteredLogsData.length : 0);
  }, [filteredLogsData]);

  const columns = [
    {
      title: 'Timestamp',
      displayProperty: 'formattedTime',
      sortProperty: 'timestamp',
      width: '25%',
    },
    {
      title: 'Vehicle Name',
      displayProperty: 'vehicle_label',
      width: '25%',
    },
    {
      title: 'Action',
      displayProperty: 'action',
      width: '25%',
      render({ rowData, cellJsx }) {
        return <span title={'Action: ' + rowData.action + ' Geofence'}>{cellJsx} Geofence</span>;
      },
    },
    {
      title: 'Geofence Status',
      displayProperty: 'activeDisplay',
      width: '25%',
    },
  ];
  return (
    <>
      <PageListWrapper>
        <PageListHead className="head-wrapper" noBottomMargin>
          <PageListTitle>
            {geofenceName ? `Activity Logs for ${geofenceName}` : `Please choose a date range`}
          </PageListTitle>
          <DateWrapper>
            <DateRangeSubmitForm
              defaultStartDate={defaultStartDate}
              defaultEndDate={defaultEndDate}
              submitHandler={onSubmit}
              validationFunction={validateGeofenceLogsRange}
              confirmText="Generate"
            />
          </DateWrapper>
          {serverError && (
            <NoDataDiv>Server error: No message was returned from the server</NoDataDiv>
          )}
        </PageListHead>
        {filteredLogsData?.length > 0 && (
          <ExportWrapper>
            <ExportDiv>
              <GeofenceActivityExportButton
                geofenceLogsData={geofenceLogsData}
                geofenceName={geofenceName}
              />
            </ExportDiv>
          </ExportWrapper>
        )}
        {filteredLogsData && (
          <>
            <h5>
              {listingCount === geofenceLogsData?.length
                ? `Total of ${listingCount} Log${listingCount !== 1 ? 's' : ''}`
                : `${listingCount} of ${geofenceLogsData?.length} Log${
                    filteredLogsData?.length !== 1 ? 's' : ''
                  }`}
            </h5>
            <TableCheckboxFilterWrapper>
              <Checkbox singleCheck>
                <CheckboxInner
                  isChecked={filterNotActiveCheckboxIsChecked}
                  onClick={() =>
                    setFilterNotActiveCheckboxIsChecked(!filterNotActiveCheckboxIsChecked)
                  }
                />
              </Checkbox>
              <TableFilterSpan>Hide logs when not active</TableFilterSpan>
            </TableCheckboxFilterWrapper>
            <Media
              queries={{
                tablet: { maxWidth: sizes.tablet },
                mobile: { maxWidth: sizes.mobile },
              }}
            >
              {matches =>
                matches.tablet ? (
                  <div>
                    <Cards>
                      {filteredLogsData.map(fld => (
                        <GeofenceLogCard log={fld} key={fld.timestamp + fld.vehicle_label} />
                      ))}
                    </Cards>
                  </div>
                ) : (
                  <WcpTable
                    tableClassName="table-simple"
                    columns={columns}
                    tableSourceData={filteredLogsData}
                    rowKey={(record, i) => i}
                    defaultSortOrder={SORT_ORDER.DESC}
                    setListingCount={setListingCount}
                    emptyText="No activity logs are currently associated with this geofence"
                  />
                )
              }
            </Media>
          </>
        )}
      </PageListWrapper>
    </>
  );
};

export default GeofenceLogsPage;
