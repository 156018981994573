import WcpTable from 'app/shared/components/controls/WcpTable/WcpTable';
import { TruncatedTextWithWrap } from 'shared/styles/components/TruncatedText';

const DeviceLogsTable = ({ deviceLogs, handleUpdateListingCount }) => {
  const columns = [
    {
      title: 'Timestamp',
      displayProperty: 'formattedTime',
      sortProperty: 'timestamp',
      width: '25%',
    },
    {
      title: 'Action',
      displayProperty: 'actionDisplay',
      width: '25%',
      render({ cellJsx, cellValue }) {
        return (
          <span title={`Action: ${cellValue} `}>
            <TruncatedTextWithWrap>{cellJsx}</TruncatedTextWithWrap>
          </span>
        );
      },
    },
    {
      title: 'Associated Vehicle',
      displayProperty: 'associatedVehicle',
      width: '15%',
    },
    {
      title: 'Details',
      displayProperty: 'details',
      render({ cellJsx, cellValue }) {
        return (
          <span title={`Detail: ${cellValue} `}>
            <TruncatedTextWithWrap>{cellJsx}</TruncatedTextWithWrap>
          </span>
        );
      },
    },
  ];

  return (
    <WcpTable
      id="deviceLogsSummaryTable"
      columns={columns}
      tableSourceData={deviceLogs ? deviceLogs : []}
      emptyText="No subscription activity associated with this device"
      setListingCount={handleUpdateListingCount}
    />
  );
};

export default DeviceLogsTable;
