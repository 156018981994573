import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getSubscriptionDevices } from 'app/features/billing/api/get/getSubscriptionDevices';

const useSubscriptionDevicesQuery = () => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);

  const subscriptionDevicesQuery = useQuery({
    queryKey: ['getSubscriptionDevicesApi'],
    queryFn: () => getSubscriptionDevices(filteredOrganizationId),
    select: (data) => {
      let subscriptionVsgs = data && Array.isArray(data) ? data : null;

      let mappedVsgs = subscriptionVsgs?.map((vsg) => {
        return Object.entries(vsg).reduce((acc, [key, value]) => {
          let newKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
          acc[newKey] = value;
          return acc;
        }, {});
      });

      return mappedVsgs;
    },
  });

  return subscriptionDevicesQuery;
};

export default useSubscriptionDevicesQuery;
