import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import ListingCount from 'shared/components/ListingCount';
import MediaWrapper from 'shared/components/MediaWrapper';
import DeviceLogsTable from 'app/features/billing/ui/components/DeviceLogsSummary/tables/DeviceLogsTable';
import DeviceLogsCardContainer from 'app/features/billing/ui/components/DeviceLogsSummary/mobile/DeviceLogsCardContainer';
import { deviceLogsData } from 'app/features/billing/services/mockData';
import { ExportDiv, ExportWrapper } from 'shared/styles/components/PageList';
import DeviceLogsExportButton from 'app/features/billing/ui/components/DeviceLogsSummary/buttons/DeviceLogsExportButton';
import { useQuery } from '@tanstack/react-query';
import { getSubscriptionLogs } from 'app/features/billing/api/get/getSubscriptionLogs';
import { useSelector } from 'react-redux';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { formatISOTimestampToLongDateWithTime } from 'shared/utilities/time';
import { deviceLogActionDictionary } from 'app/features/billing/data/enums/deviceLogActionConstants';

const DeviceLogsSummary = () => {
  const { vsgDuid } = useParams();

  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);

  const subscriptionLogsQuery = useQuery({
    queryKey: ['subscriptionLogs'],
    queryFn: () => getSubscriptionLogs({ filteredOrganizationId, vsgDuid: vsgDuid }),
  });

  const mappedDeviceLogData = subscriptionLogsQuery.data?.map((d) => {
    d.formattedTime = formatISOTimestampToLongDateWithTime(d.timestamp);
    d.associatedVehicle = d.vehicle_label;
    d.actionDisplay = deviceLogActionDictionary.find((i) => i.value === d.action)?.label;
    return d;
  });

  const [deviceLogsCount, setDeviceLogsCount] = useState(0);
  const [listingCount, setListingCount] = useState(0);

  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    if (mappedDeviceLogData?.length > 0) {
      setDeviceLogsCount(mappedDeviceLogData.length);
    }
  }, [mappedDeviceLogData]);

  if (subscriptionLogsQuery.isFetching || !subscriptionLogsQuery.data) {
    return <LoadingOverlay />;
  }
  return (
    <>
      {serverError && <NoDataDiv>Server error: No message was returned from the server</NoDataDiv>}
      {deviceLogsCount > 0 && (
        <ExportWrapper>
          <ExportDiv>
            <DeviceLogsExportButton deviceLogs={mappedDeviceLogData} />
          </ExportDiv>
        </ExportWrapper>
      )}
      {mappedDeviceLogData && (
        <>
          <ListingCount
            listingCount={listingCount}
            totalCount={deviceLogsCount}
            itemTypeName={'Log'}
          />

          <MediaWrapper
            mobileComponent={<DeviceLogsCardContainer deviceLogs={mappedDeviceLogData} />}
            nonMobileComponent={
              <DeviceLogsTable
                deviceLogs={mappedDeviceLogData}
                handleUpdateListingCount={setListingCount}
              />
            }
          />
        </>
      )}
    </>
  );
};

export default DeviceLogsSummary;
