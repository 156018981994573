import { useState, useEffect } from 'react';
import ProgressBar from 'features/ota/shared/components/ProgressBar';
import { firmwareStatus } from 'features/ota/shared/constants/firmwareStatus';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import WcpTable from 'app/shared/components/controls/WcpTable/WcpTable';
import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import { permissionData } from 'shared/constants/users';
import ListingCount from 'shared/components/ListingCount';

const ConfigurationUpdateTable = ({
  deviceUpdates,
  vehicleStatuses,
  handleUpdateConfiguration,
  configUpdatePostLoading,
  updateAllLoading,
}) => {
  deviceUpdates.forEach(du => {
    du.configStatusDisplayName = firmwareStatus[du.config_status].displayName;
    du.configStatusProgress = firmwareStatus[du.config_status].progress;
    du.configStatusButtonText = firmwareStatus[du.config_status].buttonText
      ? firmwareStatus[du.config_status].buttonText
      : null;
  });

  const [listingCount, setListingCount] = useState(deviceUpdates?.length);

  useEffect(() => {
    setListingCount(deviceUpdates?.length);
  }, [deviceUpdates]);

  return (
    <>
      <ListingCount
        listingCount={listingCount}
        totalCount={deviceUpdates.length}
        itemTypeName={'Update'}
      />
      <WcpTable
        tableClassName="table-simple"
        tableSourceData={deviceUpdates}
        rowKey={(record, i) => i}
        emptyText="No data to display."
        setListingCount={setListingCount}
        columns={[
          {
            title: 'Vehicle Name',
            displayProperty: 'label',
            render: ({ rowData, cellJsx }) => {
              return (
                <span title={'Vehicle Name: ' + rowData.label}>
                  <IconSvgComponent
                    svgFileName={
                      vehicleStatuses[rowData.vehicle_id]?.isActive
                        ? vehicleStatuses[rowData.vehicle_id]?.isResponding
                          ? 'car-outline-responding'
                          : 'car-outline-online'
                        : 'car-outline-offline'
                    }
                  />
                  &nbsp;
                  <> {cellJsx}</>
                </span>
              );
            },
          },
          {
            title: 'License Plate',
            displayProperty: 'license_plate',
          },
          {
            title: 'Current Config',
            displayProperty: 'current_config_name',
          },
          {
            title: 'Config Status',
            displayProperty: 'configStatusDisplayName',
          },
          {
            title: 'Update Progress',
            displayProperty: 'configStatusProgress',
            width: '15%',
            render: ({ rowData }) => {
              return <ProgressBar progress={rowData.configStatusProgress} />;
            },
          },
          {
            title: 'Update Action',
            displayProperty: 'configStatusButtonText',
            className: 'action',
            searchEnabled: false,
            render: ({ rowData }) => {
              return rowData.configStatusButtonText !== null &&
                rowData.configStatusButtonText !== '' &&
                !updateAllLoading ? (
                <PermissionProtectedElement requiredPermissions={[permissionData.editotaconfig]}>
                  <ButtonWithLoader
                    disabled={
                      !vehicleStatuses[rowData.vehicle_id]?.isActive ||
                      vehicleStatuses[rowData.vehicle_id]?.isResponding
                    }
                    loadingStyleProp={'otaUpdateSubmittingWithSpinner'}
                    notLoadingStyleProp={'otaUpdate'}
                    confirmText={rowData.configStatusButtonText}
                    isLoading={rowData.vehicle_id === configUpdatePostLoading}
                    clickHandler={async () => {
                      await handleUpdateConfiguration({
                        configId: rowData.config_id,
                        vehicleId: rowData.vehicle_id,
                      });
                    }}
                  />
                </PermissionProtectedElement>
              ) : (
                <></>
              );
            },
          },
        ]}
      ></WcpTable>
    </>
  );
};

export default ConfigurationUpdateTable;
