import { addDisplayCommas } from 'app/features/maintenance/utilities/formatting';
import { getUserNameDisplayFromUserList } from 'app/shared/utilities/getUserNameDisplayFromUserList';
import {
  CardContainer,
  CardIconWrapper,
  CardLabel,
  CardName,
  CardOuterWrapper,
  CardRow,
  CardValue,
} from 'shared/styles/components/MobileCard';

const DeviceLogsCard = ({ log }) => {
  return (
    <CardOuterWrapper>
      <CardContainer deviceLog>
        <CardRow potentialLongValue>
          <CardLabel>Timestamp:</CardLabel>
          <CardValue title={log.formattedTime}>{log.formattedTime}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Action:</CardLabel>
          <CardValue title={log.actionDisplay}>{log.actionDisplay}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Associated Vehicle:</CardLabel>
          <CardValue title={log.associatedVehicle}>{log.associatedVehicle}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Details:</CardLabel>
          <CardValue title={log.details}>{log.details}</CardValue>
        </CardRow>
      </CardContainer>
    </CardOuterWrapper>
  );
};

export default DeviceLogsCard;
