import React, { useState } from 'react';
import { connect } from 'react-redux';
import { resetVehicle } from 'core/redux/vehicles/actions';
import ConfirmationModalWithInnerModalPortal from 'shared/ui/modals/ConfirmationModalWithInnerModalPortal';
import { useResetVehicleMutation } from 'features/fleet/vehicles/hooks/useResetVehicleMutation';

const ResetVehicleModal = ({ vehicle, onCloseModal, resetVehicle }) => {
  const resetVehicleMutation = useResetVehicleMutation();

  return (
    <ConfirmationModalWithInnerModalPortal
      noIcon
      onCloseModal={onCloseModal}
      onSuccess={onCloseModal}
      onFailure={onCloseModal}
      bodyText={`Do you really want to reset this vehicle: `}
      boldedItem={`${vehicle.vehicleName}?`}
      secondaryBodyText={
        'Resetting the vehicle will not affect Maintenance, Alerts, Add-ons, GTT, and HAAS settings. Please note pending Firmware and Configuration updates will be cleared and will need to be reapplied.'
      }
      title={`Reset Vehicle`}
      handleRequestClose={onCloseModal}
      submitHandler={async () => {
        await resetVehicleMutation.mutateAsync({ vehicleId: vehicle.vehicleId });
      }}
      confirmText="Reset Vehicle"
      cancelHandler={onCloseModal}
      cancelText="Cancel"
    />
  );
};

export default ResetVehicleModal;
