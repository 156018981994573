import { SORT_ORDER } from 'shared/constants/table';

const sortDataByProperty = ({ sourceData, sortProperty, sortOrder, caseInsenstive = true }) => {
  return sourceData.sort((a, b) => {
    let aVal = caseInsenstive ? a[sortProperty] : a[sortProperty].toLowerCase();
    let bVal = caseInsenstive ? b[sortProperty] : b[sortProperty].toLowerCase();

    if (aVal < bVal) return sortOrder === SORT_ORDER.ASC ? -1 : 1;
    if (aVal > bVal) return sortOrder === SORT_ORDER.ASC ? 1 : -1;

    return 0;
  });
};

export default sortDataByProperty;
