const { apiCall } = require('core/api/API');

export const getSubscriptionOrders = async filteredOrganizationId => {
  const apiResponse = await apiCall({
    method: 'get',
    path: `/subscriptions/orders`,
    queryStringParameters: {
      organization_id: `${filteredOrganizationId}`,
    },
  });
  return apiResponse.data.message.summary;
};
