/**
 * @External dependencies
 */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

/**
 * @Internal dependencies
 */
import { fetchConfigurations, editConfiguration } from 'core/redux/configurations/actions';

import useAPIData from 'shared/hooks/useAPIData';

import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { SectionBody } from 'shared/styles/components/Section';
import { ActionButtonWithIcon, ActionButtonText } from 'shared/styles/components/Button';
import ModalPortal from 'shared/modals/ModalPortal';
import ConfigurationUploadModal from 'shared/modals/ConfigurationUploadModal';
import WcpTable from 'app/shared/components/controls/WcpTable/WcpTable';
import TabView from 'shared/ui/tabs/TabView';
import { TableTab, ConfigurationsTableNav } from 'shared/styles/components/Tabs';
import { TableSpinner } from 'shared/styles/components/Spinner';
import PageListWrapper, {
  PageListActionButtonContainer,
  PageListTitle,
  PageListHead,
} from 'shared/styles/components/PageList';

import colors from 'shared/constants/colors';
import { permissionData } from 'shared/constants/users';
import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import { checkForUserPermission } from 'core/redux/user/selectors';
import { formatISOTimestampToLongDateWithTime } from 'shared/utilities/time';
import MediaWrapper from 'shared/components/MediaWrapper';
import ConfigurationLibraryCardContainer from 'app/features/configurations/mobile/ConfigurationLibraryCardContainer';
import ConfigurationLibraryActionMenu from 'app/features/configurations/menus/ConfigurationLibraryActionMenu';
import { getConfigTypeLabel } from 'app/features/configurations/utilities/configUtilities';
import { sortAscendingAlpha } from 'shared/utilities/general';
import { ConfigurationActionButtonsDiv } from 'shared/styles/components/OtaUpdates';

const ConfigurationLibraryPage = ({
  configurations,
  fetchConfigurations,
  filteredOrganizationId,
  editAccess,
}) => {
  const configurationsLoaded = useAPIData({
    fetchAPIData: fetchConfigurations,
    dependencies: [filteredOrganizationId],
  });
  const [uploadModalActive, setUploadModalActive] = useState(false);

  configurations.sort((a, b) => sortAscendingAlpha(a.configName, b.configName)),
    useEffect(() => {
      if (configurations) {
        configurations.forEach(configuration => {
          configuration.formattedTime =
            configuration.uploadedTime !== 'UNKNOWN'
              ? formatISOTimestampToLongDateWithTime(configuration.uploadedTime)
              : configuration.uploadedTime;
          configuration.configTypeLabel = getConfigTypeLabel(configuration.acmProductId);
          configuration.supportedDevices = configuration.deviceCount.toString();
        });
      }
    }, [configurations]);

  const [columns] = useState([
    {
      title: 'Configuration Name',
      displayProperty: 'configName',
      onHeaderCell: () => ({ style: styles.configurationNameHeaderCell }),
      onCell: () => ({ style: styles.configurationNameCell }),
      render({ rowData, cellJsx }) {
        return editAccess ? (
          <a onClick={() => setEditModalActive(rowData)} style={styles.configurationNameLink}>
            <span
              style={styles.vehicleNameAndIcon}
              title={'Configuration Name: ' + rowData.configName}
            >
              {rowData.loadingStatus ? (
                <TableSpinner />
              ) : (
                <IconSvgComponent
                  svgFileName={'gears'}
                  alt="Configuration file"
                  svgStyle={styles.rowConfigurationIcon}
                />
              )}

              {cellJsx}
            </span>
          </a>
        ) : (
          <span
            style={styles.vehicleNameAndIcon}
            title={'Configuration Name: ' + rowData.configName}
          >
            <IconSvgComponent
              svgFileName={'gears'}
              alt="Configuration file"
              svgStyle={styles.rowConfigurationIcon}
            />
            {value}
          </span>
        );
      },
    },

    {
      title: 'Configuration Type',
      displayProperty: 'configTypeLabel',
    },

    {
      title: 'Configuration ID',
      displayProperty: 'configId',
    },

    {
      title: 'Uploaded On',
      displayProperty: 'formattedTime',
      sortProperty: 'uploadedTime',
    },

    {
      title: 'Supported Devices',
      displayProperty: 'supportedDevices',
    },
    {
      title: 'action',
      className: 'action',
      searchEnabled: false,
      render({ rowData }) {
        return <ConfigurationLibraryActionMenu configData={rowData} />;
      },
    },
  ]);

  return configurationsLoaded ? (
    <PageListWrapper centered={!(configurations && configurations.length > 0)}>
      <PageListHead stackable noBottomMargin>
        <PageListTitle>Configurations</PageListTitle>
        <PageListActionButtonContainer>
          <ConfigurationActionButtonsDiv>
            <PermissionProtectedElement requiredPermissions={[permissionData.editotaconfig]}>
              <ActionButtonWithIcon create onClick={() => setUploadModalActive(!uploadModalActive)}>
                <ActionButtonText>Upload Configuration</ActionButtonText>
                <IconSvgComponent
                  svgFileName={'arrow-up'}
                  alt="Upload"
                  style={styles.uploadButtonIcon}
                />
              </ActionButtonWithIcon>
            </PermissionProtectedElement>
          </ConfigurationActionButtonsDiv>
        </PageListActionButtonContainer>
      </PageListHead>
      {configurations && configurations.length > 0 ? (
        <React.Fragment>
          <SectionBody>
            <TabView
              tabContainer={ConfigurationsTableNav}
              tabs={[
                {
                  tabElement: <TableTab style={{ flex: '0 1 25%' }}>Library</TableTab>,
                  content: (
                    <MediaWrapper
                      mobileComponent={
                        <ConfigurationLibraryCardContainer
                          configurationList={configurations.filter(
                            configuration => configuration.archived === 'false',
                          )}
                        />
                      }
                      nonMobileComponent={
                        <WcpTable
                          tableClassName="table-simple"
                          columns={columns}
                          tableSourceData={configurations.filter(
                            configuration => configuration.archived === 'false',
                          )}
                          rowKey={(record, i) => i}
                        />
                      }
                    />
                  ),
                },
                {
                  tabElement: <TableTab style={{ flex: '0 1 25%' }}>Archive</TableTab>,
                  content: (
                    <MediaWrapper
                      mobileComponent={
                        <ConfigurationLibraryCardContainer
                          configurationList={configurations.filter(
                            configuration => configuration.archived === 'true',
                          )}
                        />
                      }
                      nonMobileComponent={
                        <WcpTable
                          className="table-simple"
                          columns={columns}
                          tableSourceData={configurations.filter(
                            configuration => configuration.archived === 'true',
                          )}
                          rowKey={(record, i) => i}
                        />
                      }
                    />
                  ),
                },
              ]}
            />
          </SectionBody>
        </React.Fragment>
      ) : (
        <SectionBody>
          {editAccess ? (
            <React.Fragment>
              <IconSvgComponent svgFileName="cloud-large" alt="Cloud icon" />
              <h4>Upload your first configuration</h4>
              <ActionButtonWithIcon
                onClick={() => {
                  setUploadModalActive(!uploadModalActive);
                }}
              >
                <ActionButtonText>Upload Configuration</ActionButtonText>
                <IconSvgComponent
                  svgFileName={'arrow-up'}
                  alt="Upload"
                  style={styles.uploadButtonIcon}
                />
              </ActionButtonWithIcon>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <IconSvgComponent
                svgStyle={styles.splashIcon}
                svgFileName={'gears-grey'}
                alt="Configurations"
                beforeInjection={svg => {
                  svg.setAttribute('width', 187);
                  svg.setAttribute('height', 187);
                  svg
                    .querySelectorAll('path')
                    .forEach(path => path.setAttribute('fill', colors.midnight));
                }}
              />
              <h4>No Configurations Available</h4>
            </React.Fragment>
          )}
        </SectionBody>
      )}
      {uploadModalActive ? (
        <ModalPortal onRequestClose={() => setUploadModalActive(false)}>
          <ConfigurationUploadModal onSuccess={() => setUploadModalActive(false)} />
        </ModalPortal>
      ) : null}
    </PageListWrapper>
  ) : (
    <LoadingOverlay />
  );
};

const styles = {
  tableHead: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableTitle: {
    display: 'inline-block',
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    padding: '10px 15px 10px 18px',
  },
  uploadButtonIcon: {
    marginBottom: '0px',
    marginLeft: '11px',
    width: '10px',
  },
  buttonPlusIcon: {
    position: 'absolute',
    right: 15,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  rowConfigurationIcon: {
    marginRight: 10,
    fontSize: 0,
    width: '24px',
  },
  configurationNameHeaderCell: {
    paddingLeft: 0,
  },
  configurationNameCell: {
    alignItems: 'center',
    color: colors.midnight,
    fontSize: 14,
    paddingLeft: 0,
  },
  configurationNameLink: {
    textDecoration: 'none',
  },
  configurationIDCell: {
    color: colors.midnight,
  },
  deviceTooltipList: {
    listStyle: 'none',
  },
};

export default connect(
  state => ({
    configurations: state.configurations.configurations,
    filteredOrganizationId: state.user.filteredOrganizationId,
    editAccess: checkForUserPermission(state.user, permissionData.editotaconfig),
  }),
  dispatch => ({
    fetchConfigurations: () => dispatch(fetchConfigurations()),
  }),
)(ConfigurationLibraryPage);
