import {
  organizationPath,
  organizationFSEsPath,
  associateFSEstoOrganizationPath,
  disassociateFSEFromOrganizationPath,
} from 'shared/constants/api';
import { apiCall } from 'core/api/API';
import { replaceEmptyString } from 'shared/utilities/general';
import {
  formatDateFromDynamoIsoString,
  formatDateToDynamoIsoString,
  formatDateValueFromDynamoIsoString,
} from 'shared/utilities/organizations';

export const undecorateOrganizationObject = (organization) => ({
  ...(organization.organizationId && { organization_id: organization.organizationId }),
  ...(organization.organizationName && { organization_name: organization.organizationName }),
  ...(organization.vehicleLimit && { vehicle_limit: organization.vehicleLimit }),
  ...(organization.activeUsers && { active_users: organization.activeUsers }),
  ...(organization.city && { address_city: organization.city }),
  ...(organization.state && { address_state: organization.state }),
  ...(organization.address1 && { address_1: organization.address1 }),
  ...(organization.address2 && { address_2: organization.address2 }),
  ...(organization.contactPhone && { contact_phone: organization.contactPhone }),
  ...(organization.contactPhoneExtension && {
    contact_phone_extension: organization.contactPhoneExtension,
  }),
  ...(organization.contactEmail && { contact_email_id: organization.contactEmail }),
  ...(organization.contactName && { contact_name: organization.contactName }),
  ...(organization.customerType && { customer_type: organization.customerType }),
  ...(organization.timeZone && { timezone: organization.timeZone }),
  ...(organization.startDate && {
    start_date: formatDateToDynamoIsoString(organization.startDate),
  }),
  ...(organization.endDate && { end_date: formatDateToDynamoIsoString(organization.endDate) }),
  ...(organization.active !== undefined && { organization_active: organization.active }),
  ...(organization.associatedFSE && { associated_fse: organization.associated_fse }),
  ...(organization.gttEnabled !== undefined && { gtt_enabled: organization.gttEnabled }),
  ...(organization.gttSetup !== undefined && { gtt_setup: organization.gttSetup }),
  ...(organization.haasEnabled !== undefined && { haas_enabled: organization.haasEnabled }),
  ...(organization.thruGreenEnabled !== undefined && {
    thrugreen_enabled: organization.thruGreenEnabled,
  }),
  ...(organization.appliedInformationEnabled !== undefined && {
    applied_information_enabled: organization.appliedInformationEnabled,
  }),
  ...(organization.appliedInformationBusinessId !== undefined && {
    applied_information_business_id: organization.appliedInformationBusinessId,
  }),
  ...(organization.alertsActive !== undefined && {
    alerts_active: organization.alertsActive,
  }),
  ...(organization.haasSetup !== undefined && { haas_setup: organization.haasSetup }),
  ...(organization.wimAccountId && { wim_account_id: organization.wimAccountId }),
  ...(organization.action && { action: organization.action }),
  ...(organization.vehicleCount && { vehicle_count: organization.vehicleCount }),
  ...(organization.reminderMileage && {
    maintenance_reminder_mileage: organization.reminderMileage,
  }),
  ...(organization.reminderEngineHours && {
    maintenance_reminder_engine_hours: organization.reminderEngineHours,
  }),
  ...(organization.reminderDays && {
    maintenance_reminder_days: organization.reminderDays,
  }),
  ...(organization.reminderOilLife && {
    maintenance_reminder_oil_life: organization.reminderOilLife,
  }),
});

export const decorateOrganizationObject = (organization) => ({
  organizationId: organization.organization_id,
  organizationName: organization.organization_name,
  vehicleLimit: organization.vehicle_limit,
  activeUsers: organization.active_users,
  city: organization.address_city,
  state: organization.address_state,
  address1: organization.address_1,
  address2: organization.address_2,
  contactPhone: organization.contact_phone,
  contactPhoneExtension: replaceEmptyString(organization.contact_phone_extension),
  contactEmail: organization.contact_email_id,
  contactName: organization.contact_name,
  customerType: organization.customer_type,
  timeZone: organization.timezone,
  startDate: formatDateFromDynamoIsoString(organization.start_date),
  endDate: formatDateFromDynamoIsoString(organization.end_date),
  active: organization.organization_active,
  associatedFSE: organization.associated_fse,
  gttEnabled: organization.gtt_enabled,
  gttSetup: organization.gtt_setup,
  haasEnabled: organization.haas_enabled,
  alertsActive: organization.alerts_active,
  haasSetup: organization.haas_setup,
  status: organization.organization_active ? 'Enabled' : 'Disabled',
  wimAccountId: organization.wim_account_id,
  startDateValue: formatDateValueFromDynamoIsoString(organization.start_date),
  endDateValue: formatDateValueFromDynamoIsoString(organization.end_date),
  vehicleCount: organization.vehicle_count,
  reminderMileage: organization.maintenance_reminder_mileage,
  reminderEngineHours: organization.maintenance_reminder_engine_hours,
  reminderDays: organization.maintenance_reminder_days,
  reminderOilLife: organization.maintenance_reminder_oil_life,
  thruGreenEnabled: organization.thrugreen_enabled,
  appliedInformationEnabled: organization.applied_information_enabled,
  appliedInformationBusinessId: organization.applied_information_business_id,
  subscriptionGracePeriodEndDate: organization.subscription_grace_period_end_date,
  subscriptionEndDate: organization.subscription_end_date,
  subscriptionStatus: organization.subscription_status,
});

export const decorateFSE = (FSE) => ({
  ...FSE,
  username: FSE.Username,
  givenName: FSE.given_name,
  familyName: FSE.family_name,
});

export const getOrganizations = async ({ organizationIds = [] }) => {
  const queryStringParameters =
    organizationIds.length > 0
      ? {
          organization: `[${organizationIds.map((organizationId) => `${organizationId}`).join(',')}]`,
        }
      : null;
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: organizationPath,
    method: 'get',
    queryStringParameters,
  });
};

export const getOrganization = async (organizationId) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/organization/${organizationId}`,
    method: 'get',
  });
};

export const createOrganizationAPI = async (organization) => {
  organization.organizationName = organization.organizationName.trim();
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: organizationPath,
    method: 'post',
    body: undecorateOrganizationObject(organization),
  });
};

export const updateOrganizationAPI = async (organization) => {
  organization.organizationName = organization.organizationName.trim();
  if (organization.active === true) {
    organization.action = 'Activate';
  } else if (organization.active === false) {
    organization.action = 'Deactivate';
  } else {
    organization.action = 'Edit';
  }
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${organizationPath}/${organization.organizationId}`,
    method: 'put',
    body: undecorateOrganizationObject(organization),
  });
};

export const associateFSEsToOrganization = async ({ FSEs, organizationId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: associateFSEstoOrganizationPath(organizationId),
    body: {
      associated_fse: FSEs,
    },
    method: 'post',
  });
};

export const getAssociatedFSEData = async ({ organizationId, username }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    method: 'get',
    path: `/organization/${organizationId}/associated_fse`,
    queryStringParameters: {
      username: username,
    },
  });
};

export const disassociateFSEFromOrganization = async ({ username, organizationId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: disassociateFSEFromOrganizationPath({ username, organizationId }),
    method: 'del',
  });
};

export const fetchOrganizationFSEs = async (organizationId) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: organizationFSEsPath(organizationId),
    method: 'get',
  });
};

export const savePermissions = async ({ organizationId, permissions }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/organization/${organizationId}/permissions`,
    method: 'put',
    body: permissions,
  });
};

export const restorePermissions = async ({ organizationId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/organization/${organizationId}/resetpermissions`,
    method: 'put',
  });
};
export const generateAccessToken = async ({ organizationId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/organization/${organizationId}/generate_gtt_token`,
    method: 'post',
  });
};
