import { Cards } from 'shared/styles/components/MobileCard';
import DevicesAndBillingSummaryCard from 'app/features/billing/ui/components/DevicesAndBillingSummary/mobile/DevicesAndBillingSummaryCard';
import DevicesAndBillingActionMenu from 'app/features/billing/ui/components/DevicesAndBillingSummary/menus/DevicesAndBillingActionMenu';
import useDevicesAndBillingSummaryProviderContext from 'app/features/billing/services/providers/hooks/useDevicesAndBillingSummaryProviderContext';

const DevicesAndBillingSummaryCardContainer = () => {
  const {
    state: { filteredDeviceSubscriptions },
  } = useDevicesAndBillingSummaryProviderContext();

  const getActionMenu = device => {
    return <DevicesAndBillingActionMenu deviceId={device.vsgDuid} />;
  };

  return (
    <div>
      <Cards>
        {filteredDeviceSubscriptions.map(ds => (
          <DevicesAndBillingSummaryCard
            deviceSubscription={ds}
            key={ds.id}
            actionMenuComponent={() => getActionMenu(ds)}
          />
        ))}
      </Cards>
    </div>
  );
};

export default DevicesAndBillingSummaryCardContainer;
