import React, { useState } from 'react';

import VehicleCreateFormContainer from 'features/fleet/vehicles/components/forms/VehicleCreateFormContainer';
import PageListWrapper from 'shared/styles/components/PageList';
import { useNavigate } from 'react-router-dom';
import useSubscriptionDevicesQuery from 'app/features/vehicles/hooks/queries/useSubscriptionDevicesQuery';
import LoadingWrapper from 'shared/components/LoadingWrapper';

const VehicleCreatePage = ({ userState }) => {
  const navigate = useNavigate();
  const navigateBackToVehicleList = () => {
    navigate('/vehicles');
  };

  const subscriptionDevicesQuery = useSubscriptionDevicesQuery();

  return (
    <PageListWrapper>
      <LoadingWrapper
        isLoading={subscriptionDevicesQuery.isFetching && !subscriptionDevicesQuery.data}
      >
        <VehicleCreateFormContainer
          handleRedirect={navigateBackToVehicleList}
          userState={userState}
          mappedVsgs={subscriptionDevicesQuery.data}
        />
      </LoadingWrapper>
    </PageListWrapper>
  );
};

export default VehicleCreatePage;
