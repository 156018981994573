import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { checkUpdates } from 'core/api/firmware';
import { useState } from 'react';

const useCheckForUpdatesMutation = () => {
  const queryClient = useQueryClient();
  // redux
  const currentOrganizationId = useSelector(state => state.user.filteredOrganizationId);

  const [checkUpdatesRunning, setCheckUpdatesRunning] = useState(false);

  // checks for updates
  const checkUpdatesMutation = useMutation({
    mutationFn: () => {
      checkUpdates(currentOrganizationId);
      queryClient.invalidateQueries(['fetchFirmwareUpdatesQuery']);
      queryClient.invalidateQueries(['fetchFirmwareUpdatesByVehicleQuery']);
    },
  });

  // run checkUpdatesMutation on load
  useEffect(() => {
    checkUpdatesMutation.mutate();
  }, [currentOrganizationId]);

  useEffect(() => {
    if (!checkUpdatesMutation.isLoading) {
      setCheckUpdatesRunning(false);
    }
  }, [checkUpdatesMutation.isLoading]);

  return [checkUpdatesMutation, setCheckUpdatesRunning, checkUpdatesRunning];
};

export default useCheckForUpdatesMutation;
