import { mapDeviceLogsDataToCsv } from 'app/features/billing/services/mapping/deviceLogsExportMapping';
import ExportCsvButton from 'shared/widgets/ExportCsvButton';

const DeviceLogsExportButton = ({ deviceLogs }) => {
  const exportData = mapDeviceLogsDataToCsv({ deviceLogs });

  return (
    <ExportCsvButton
      exportData={exportData}
      fileNamePrefix={'wcp_device_logs_export'}
      id="deviceLogsExportButton"
    />
  );
};

export default DeviceLogsExportButton;
