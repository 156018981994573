import DevicesAndBillingSummaryProvider from 'app/features/billing/services/providers/DevicesAndBillingSummaryProvider/DevicesAndBillingSummaryProvider';
import DevicesAndBillingSummary from 'app/features/billing/ui/components/DevicesAndBillingSummary/DevicesAndBillingSummary';
import PageListWrapper, { PageListHead, PageListTitle } from 'shared/styles/components/PageList';

const DevicesAndSubscriptionsPage = () => {
  return (
    <DevicesAndBillingSummaryProvider>
      <PageListWrapper>
        <PageListHead stackable subscriptions>
          <PageListTitle>Devices and Subscriptions</PageListTitle>
        </PageListHead>
        <DevicesAndBillingSummary />
      </PageListWrapper>
    </DevicesAndBillingSummaryProvider>
  );
};

export default DevicesAndSubscriptionsPage;
